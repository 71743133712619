<template>
    <div class="projectCaseDetails_page">
        <Banner :adCategory="3"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="content">
            <div class="title">{{ formData.projectCaseName }}</div>
            <div class="introduce display"> {{ formData.projectName }} </div>
            <div class="el_carousel_box">
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item, index) in formData.imgUrls" :key="index">
                        <div class="el_carousel_cont display">
                            <img class="pointer" :src="item" alt="">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="project_main flex">
                <div class="project_list">
                    <div class="project_details flex-center nowrap">
                        <img class="icon_img" src="@/assets/img/projectCase/1.png" alt="">
                        <div class="name">项目时间</div>
                    </div>
                    <div class="details nowrap"> {{ formData.projectTime }} </div>
                </div>
                <div class="project_list">
                    <div class="project_details flex-center nowrap">
                        <img class="icon_img" src="@/assets/img/projectCase/2.png" alt="">
                        <div class="name">项目地址</div>
                    </div>
                    <div class="details nowrap">{{ formData.projectAddress }}</div>
                </div>
                <div class="project_list">
                    <div class="project_details flex-center nowrap">
                        <img class="icon_img" src="@/assets/img/projectCase/3.png" alt="">
                        <div class="name ">施工部位
                        </div>
                    </div>
                    <div class="details nowrap">{{ formData.constructionSite }}</div>
                </div>
                <div class="project_list">
                    <div class="project_details flex-center nowrap">
                        <img class="icon_img" src="@/assets/img/projectCase/4.png" alt="">
                        <div class="name">工程量
                        </div>
                    </div>
                    <div class="details nowrap">{{ formData.amountWork }}</div>
                </div>
            </div>
        </div>
        <div class="project_introduction">
            <div class="content">
                <div class="project_case project_title">项目简介</div>
                <div class="rich_text" v-html="formData.content">

                </div>
            </div>
        </div>
        <div class="correlation">
            <div class="content">
                <div class="project_case">相关案例</div>
            </div>
            <div class="slideshow diusplay">
                <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item" v-for="(list, index) in correlationCaseList" :key="index">
                        <div class="carouselData ">
                            <div class="company_box flex flex-wrap">
                                <div class="list " v-for="(imgList, index2) in list" :key="index2"
                                    @click="details(imgList)">
                                    <div style="overflow:hidden;">
                                        <img class="icon_bg" :src="imgList.coverUrl" alt="">
                                    </div>
                                    <div class="cont">
                                        <div class="company_name">{{ imgList.projectCaseName }}</div>
                                        <div class="company_introduce astrict">
                                            {{ imgList.caseIntroduction }}</div>
                                        <img class="icon_bg icon_n" :src="icon_n" alt="">
                                        <img class="icon_bg icon_y" :src="icon_y" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/breadcrumb'
import Banner from '@/components/banner'
export default {
    name: "projectCaseDetails",
    components: {
        Banner,
        Breadcrumb,
    },
    data() {
        return {
            ids: null,
            icon_n: require('@/assets/img/homes/icon_n.png'),
            icon_y: require('@/assets/img/homes/icon_y.png'),
            breadcrumbList: [
                {
                    name: "工程案例",
                    url: ''
                },
            ],
            correlationCaseList: [],
            formData: {},
            params: {},
        }
    },
    // 监听路由变化
    watch: {
        $route: "urlName",
    },
    created() {

        this.breadcrumbList = [
            {
                name: "工程案例",
                url: ''
            },
            {
                name: this.$route.query.fatherName,
                url: "",
            },
            {
                name: this.$route.query.name,
                url: ''
            }
        ]
        this.ids = this.$route.query.id
        this.getData()
        this.getList()
    },
    methods: {
        // 监听url参数变化
        urlName() {

            this.breadcrumbList = [
                {
                    name: "工程案例",
                    url: ''
                },
                {
                    name: this.$route.query.fatherName,
                    url: "",
                },
                {
                    name: this.$route.query.name,
                    url: ''
                }
            ]
            this.getData()
            this.getList()
        },
        getList() {
            let params = {
                page: 1,
                limit: 10,
                projectCaseCategoryId: this.$route.query.fatheId
            }
            this.api.findPageProjectCase(params).then(res => {
                let arr = []
                res.data.forEach(item => {
                    if (item.id != this.$route.query.id) {
                        arr.push(item)
                    }
                })
                this.correlationCaseList = this.init(arr, 3)
            })
        },
        getData() {
            this.api.findProjectCaseDetails({ id: this.$route.query.id }).then(res => {
                this.formData = res.data
            })
        },
        //轮播图初始化
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return dataList = [...newDataList]
        },
        details(item) {
            this.$router.push({
                path: '/projectCaseDetails',
                query: {
                    id: item.id,
                    fatheId: this.$route.query.fatheId,
                    fatherName: this.$route.query.fatherName,
                    name: item.projectCaseName
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.projectCaseDetails_page {
    .breadcrumb_box {
        background: #f4f5f5;
    }

    .title {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        margin-top: 76PX;
        line-height: 32px;
        text-align: center;
    }

    .introduce {
        width: 834px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        margin: 0 auto;
        margin-top: 21px;
        text-align: center;
    }

    .el_carousel_box {
        margin-top: 41px;

        .el_carousel_cont {
            img {
                width: 834px;
                height: 600px;
            }
        }

        ::v-deep.el-carousel__arrow--right {
            right: 80px;
        }

        ::v-deep.el-carousel__arrow--left {
            left: 80px;
        }
    }

    ::v-deep.el-carousel__indicators {
        display: none;
    }

    ::v-deep.el-carousel {
        .el-carousel__container {
            height: 600px;

            .el-carousel__arrow {
                width: 54px;
                height: 54px;
                font-size: 24px;
                background: none;
                border: 1px solid #e62129;
                color: #e62129 !important;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

    .project_main {
        margin-top: 77px;
        padding-left: 66px;
        padding-bottom: 50px;
        margin-bottom: 65px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebebeb;

        .project_list:nth-child(2) {
            .icon_img {
                width: 13px !important;
            }
        }

        .project_list {
            margin-right: 200px;

            .project_details {
                .icon_img {
                    width: 16px;
                    height: 16px;
                }

                .name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #E62129;
                    margin-left: 13px;
                }
            }

            .details {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                margin-top: 20px;
                margin-left: 30px;
            }
        }
    }

    .correlation {
        height: 752px;
        background: #f4f5f5;
        padding-top: 80px;

    }

    .slideshow {
        width: 1400px;
        margin: 0 auto;

        .carouselData {
            width: 1200px;
        }

        ::v-deep.el-car-item {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 106px;
        }
    }

    .company_box {
        margin-top: 39px;

        .list:nth-child(1) {
            margin-left: 0 !important;
        }

        .list:hover {
            .icon_bg {
                transform: scale(1.1);
            }

            .company_name {
                color: #E62129 !important;
            }

            .company_introduce {
                color: #E62129 !important;
            }

            .icon_n {
                display: none !important;
            }

            .icon_y {
                display: block !important;
            }
        }

        .list:nth-child(3n+1) {
            margin-left: 0px !important;
        }

        .list {
            width: 380px;
            height: 500px;
            background: white;
            margin-left: 30px;
            margin-bottom: 33px;
            overflow: hidden;
            box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);

            .icon_bg {
                width: 380px;
                height: 270px;
                transition: All 0.8s ease;
            }

            .cont {
                padding-top: 43px;
                padding-left: 33px;
                box-sizing: border-box;

                .company_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 18px;
                }

                .company_introduce {
                    width: 306px;
                    height: 40px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 22px;
                    margin-top: 30px;
                }

                .icon_bg {
                    width: 48px;
                    height: 34px;
                    margin-top: 36px;
                }

                .icon_n {
                    display: block;
                }

                .icon_y {
                    display: none;
                }
            }
        }
    }

    .project_case {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
    }

    .project_introduction {
        padding-bottom: 58px;

        .project_title {
            margin-bottom: 39px;
        }
    }
}
</style>